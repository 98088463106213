import React, { useEffect } from 'react'
import { Button } from 'reactstrap'
import { MdQrCodeScanner } from 'react-icons/md'
import ScanMyEFNDialog from './ScanMyEFNDialog'
import KeinePunktemeldungen from './KeinePunktemeldungen'
import ListOfPunktemeldungen from './ListOfPunktemeldungen'
import NotificationsNotSupportedHinweis from '../components/NotificationsNotSupportedHinweis'
import ActivateNotifications from './ActivateNotifications'
import ActivateNotificationsAlert from './ActivateNotificationsAlert'

export const Punktemeldungen = (props) => {

	const { efn, fetchPunktemeldungen, showScanMyEFNDialog, efnSetupCompleted = true, bearer,
		networkActionsDisabled } = props
		useEffect(() => {
			if( efn !== undefined && efnSetupCompleted){
				fetchPunktemeldungen(efn, bearer);
			}	
		// eslint-disable-next-line	
		}, [efn, efnSetupCompleted]);
	if (!efnSetupCompleted) return null

	return (
		<>
			<ScanMyEFNDialog></ScanMyEFNDialog>
			<ActivateNotifications className="mb-4"/>
			<ActivateNotificationsAlert className="mt-3 mb-3"/>		
			<div className="h5">Erfolgte Punktemeldungen</div>
			<NotificationsNotSupportedHinweis className="mt-3"/>
			<KeinePunktemeldungen/>
			<Button color="light" block disabled={networkActionsDisabled} 
				onClick={() => fetchPunktemeldungen(efn, bearer)} >neuesten Stand abrufen</Button>
			<ListOfPunktemeldungen className="mb-4">
			</ListOfPunktemeldungen>
			<div className="mb-2 mt-4">
				<Button color="secondary" onClick={ () => showScanMyEFNDialog() }>EFN Barcode anzeigen <MdQrCodeScanner fontSize="1em" color="orange" ></MdQrCodeScanner></Button>
			</div>

		</>
	)
}