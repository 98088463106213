import React from 'react';
import EIVHeader from './EIVHeader';
import { Button } from 'reactstrap'
import { CSS_CLASSNAME_PANEL } from './EIVCommons';
import Veranstaltungen from './components/Veranstaltungsliste';
import AddVeranstaltungPanel from './AddVeranstaltungPanel'
import { setScreen } from './store/appNavigation/actions';
import { connect } from 'react-redux'
// import { Beispiele } from './components/Bespiele';
import { addVeranstaltungPanelSetVNR, addVeranstaltungPanelSetVNRPwd, setShowAddVeranslatungsPanel } from './store/veranstaltungen/actions';


const VeranstalterPage = (props) => {
	// const { showAddVeranstaltungArea, setShowAddVeranslatungsPanel, changeVNR, changeVNRPwd } = props
	const { showAddVeranstaltungArea, setShowAddVeranslatungsPanel } = props
	return (
		<div className="container">
			<EIVHeader></EIVHeader>
			<div className="h3">Meine Veranstaltungen</div>
			<div className={CSS_CLASSNAME_PANEL}>
				<Veranstaltungen />
				<br />
				{!showAddVeranstaltungArea &&
					<Button color="secondary" /*outline*/ onClick={() => setShowAddVeranslatungsPanel(true)} >Veranstaltung hinzufügen</Button>
				}
				{showAddVeranstaltungArea &&
					<AddVeranstaltungPanel toggleAddVNRPanel={() => setShowAddVeranslatungsPanel(false)}>
						{/* <Beispiele className="clickableValue" title="Beispiele: VNR - PWD">
							<span className='text-muted'>einfach anklicken ...</span>
							<div onClick={(e) => { changeVNR("2760602019006660004"); changeVNRPwd("lz01invf") }}>
								2760602019006660004 - lz01invf</div>
							<div onClick={(e) => { changeVNR("2760602020000070000"); changeVNRPwd("qwsnxwet") }}>
								2760602020000070000 - qwsnxwet</div>
							<div onClick={(e) => { changeVNR("2760602018000090002"); changeVNRPwd("53ae4q7g") }}>
								2760602018000090002 - 53ae4q7g Kategorie D mit Teilnahmedatum</div>
							<div onClick={(e) => { changeVNR("2760602019006650005"); changeVNRPwd("kp7dewr6") }}>
								2760602019006650005 - kp7dewr6</div>
							<div onClick={(e) => { changeVNR("2760602019306980003"); changeVNRPwd("0zh1uhit") }}>
								2760602019306980003 - 0zh1uhit</div>
							<div onClick={(e) => { changeVNR("2760602019302080006"); changeVNRPwd("22cl7foj") }}>
								2760602019302080006 - 22cl7foj</div>
							<div onClick={(e) => { changeVNR("2760602019256100008"); changeVNRPwd("evw0j45l") }}>
								2760602019256100008-evw0j45l</div>
						</Beispiele> */}
					</AddVeranstaltungPanel>

				}
			</div>


		</div>
	)
}
const mapStateToProps = (state, ownProps) => {
	return {
		showAddVeranstaltungArea: state.addVeranstaltungPanel.showAddVeranstaltungArea
	}
}
const putActionsToProps = dispatch => {
	return {
		setScreen: (screen) => dispatch(setScreen(screen)),
		changeVNR: vnr => dispatch(addVeranstaltungPanelSetVNR(vnr)),
		changeVNRPwd: vnrpwd => dispatch(addVeranstaltungPanelSetVNRPwd(vnrpwd)),
		setShowAddVeranslatungsPanel: flag => dispatch(setShowAddVeranslatungsPanel(flag))
	}
};

export default connect(mapStateToProps, putActionsToProps)(VeranstalterPage);